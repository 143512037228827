<template>
  <div class="history_container">
    <div class="one_frame frame_1" v-if="currentFrame == 1">
      <span class="frame_text"
        >{{ framesContent[currentFrame - 1].text }}
      </span>
      <div class="img_container">
        <img
          :src="
            require('@/assets/img/history/history1/' +
              framesContent[currentFrame - 1].img)
          "
          class="img"
        />
      </div>
    </div>

    <div class="one_frame frame_2" v-if="currentFrame == 2">
      <span class="frame_text">{{ framesContent[currentFrame - 1].text }}</span>
      <div class="img_container">
        <img
          :src="
            require('@/assets/img/history/history1/' +
              framesContent[currentFrame - 1].img)
          "
          class="img"
        />
      </div>
    </div>

    <div class="one_frame frame_3" v-if="currentFrame == 3">
      <span class="frame_text">{{ framesContent[currentFrame - 1].text }}</span>
      <div class="img_container">
        <img
          :src="
            require('@/assets/img/history/history1/' +
              framesContent[currentFrame - 1].img)
          "
          class="img"
        />
      </div>
    </div>

    <div class="one_frame frame_4" v-if="currentFrame == 4">
      <span class="frame_text">{{ framesContent[currentFrame - 1].text }}</span>
      <div class="img_container">
        <img
          :src="
            require('@/assets/img/history/history1/' +
              framesContent[currentFrame - 1].img)
          "
          class="img"
        />
      </div>
    </div>

    <div class="one_frame frame_5" v-if="currentFrame == 5">
      <span class="frame_text">{{ framesContent[currentFrame - 1].text }}</span>
      <div class="img_container">
        <img
          :src="
            require('@/assets/img/history/history1/' +
              framesContent[currentFrame - 1].img)
          "
          class="img"
        />
      </div>
    </div>

    <div class="one_frame frame_6" v-if="currentFrame == 6">
      <span class="frame_text">{{ framesContent[currentFrame - 1].text }}</span>
      <div class="img_container">
        <img
          :src="
            require('@/assets/img/history/history1/' +
              framesContent[currentFrame - 1].img)
          "
          class="img"
        />
      </div>
    </div>

    <div class="one_frame frame_7" v-if="currentFrame == 7">
      <span class="frame_text">{{ framesContent[currentFrame - 1].text }}</span>

      <div class="choose_container">
        <div class="chooseBtns_container">
          <div class="chooseBtn chooseBtn_c">
            <img :src="require('@/assets/img/markers/mo.gif')" alt="" />
            <span>Коннектор</span>
          </div>
          <div class="chooseBtn chooseBtn_i">
            <span>Изолятор</span>
          </div>
        </div>
        <span class="choose_text">{{ chooseText }}</span>
        <button сlass="choose">ок</button>
        <span class="attention">
          после выбора поменять класс будет невозможно
        </span>
      </div>
    </div>

    <span class="nav-button next" v-show="nextBtn" @click="clickNext()">▶</span>
    <span class="nav-button prev" v-show="prevBtn" @click="clickPrev()">◀</span>
  </div>
</template>

<script>
import bridge from "@vkontakte/vk-bridge";
export default {
  name: "History_1",
  data() {
    return {
      currentFrame: 1,
      nextBtn: true,
      prevBtn: false,
      chooseText:
        "и типа коннекткоры они такие типа за то чтобы связаться и все такое и будет тогда хорошо",
      framesContent: [
        {
          img: "1.jpg",
          text: "Мы все помним, как  пришел сигнал из глубокого космоса..",
        },
        {
          img: "2.jpg",
          text: "..все телефоны, радиостанции, тв каналы, транслировали призыв о помощи..",
        },
        {
          img: "3.jpg",
          text: "..КРУШЕНИЕ..МОЖЕТЕ СПАСТИ..ОТВЕТЬТЕ..ЗАКРУТИТЕ СИГНАЛ..",
        },
        {
          img: "4.jpg",
          text: "..это разделило нас..",
          contact: false,
        },
        {
          img: "5.jpg",
          text: "..одни увидели в послании угрозу для человечества..",
          contact: false,
        },
        {
          img: "6.jpg",
          text: "..другие  шанс изменить мир к лучшему..",
          contact: true,
        },
        {
          img: "7.jpg",
          text: "..на чьей ты стороне?",
          contact: false,
        },
      ],

      autoPlayInterval: null,
    };
  },

  methods: {
    clickNext() {
      this.vibro();
      this.prevBtn = true;
      if (this.currentFrame == 6) {
        this.nextBtn = false;
      }
      this.currentFrame++;
    },
    clickPrev() {
      this.vibro();
      this.nextBtn = true;
      if (this.currentFrame == 2) {
        this.prevBtn = false;
      }
      this.currentFrame--;
    },

    vibro() {
      bridge
        .send("VKWebAppTapticImpactOccurred", {
          style: "heavy",
        })
        .then((data) => {
          if (data.result) {
            // Вибрация вызвана
          }
        })
        .catch((error) => {
          // Ошибка
          console.log(error);
        });
    },
  },

  mounted() {
    //this.startAutoPlay(); // запуск автоматической прокрутки
  },

  beforeUnmount() {
    //this.stopAutoPlay(); // очистка интервала при удалении компонента
  },
};
</script>

<style scoped>
.history_container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0c1315;
  font-family: monospace;
}

.nav-button {
  position: absolute;
  top: 50%;
  background-color: #869aa57a;
  border: 2px solid #0c1315;
  color: #0c1315;
  border-radius: 50%;
  width: 43px;
  height: 43px;
  font-size: 30px;
  cursor: pointer;
  text-align: center;
}

.nav-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.nav-button.prev {
  left: 20px;
}

.nav-button.next {
  right: 20px;
}
.one_frame {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}
.frame_text {
  color: #869aa5;
  font-family: monospace;
  font-size: 1rem;
  padding-right: 20px;
  padding-left: 20px;
  height: 10vh;
  display: flex;
  align-items: center;
}
.img_container {
  height: 90vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.img {
  height: 100%;
}
.choose_container {
  color: #869aa5;
  font-family: monospace;
  font-size: 1rem;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.chooseBtns_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.chooseBtn {
  border: 1px solid;
  padding: 7px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  max-height: 20px;
}
.attantion {
  font-size: 0.8rem;
  color: #ff342f;
}
.chooseBtn_c {
  color: aqua;
  background: #008ff5;
}
.chooseBtn_i {
  background: #ff8201;
  color: #ff342f;
}
</style>
